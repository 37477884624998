import { ADD_USER, REMOVE_USER, EDIT_USER, GET_USERS } from '../constants'

export const getUsers = () => ({
  type: GET_USERS,
})

export const addUser = userInfo => ({
  type: ADD_USER,
  payload: userInfo,
})

export const editUser = userInfo => ({
  type: EDIT_USER,
  payload: userInfo,
})

export const removeUser = userId => ({
  type: REMOVE_USER,
  payload: { userId },
})
