import * as React from 'react'

import './styles.scss'

function renderImage(i) {
  return <img key={i} className={`img-${i}`} src={`/assets/images/splash/${i}.png`} alt="" />
}

export default function LogoContainer() {
  const images = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    // '07',
    // '08',
    // '09',
    '10',
    '11',
    '12',
    'logo-full',
  ]

  return <div className="logo-container">{images.map(renderImage)}</div>
}
