import { OPEN_EDIT_USER, CLOSE_EDIT_USER, OPEN_NOTIF_MODAL, CLOSE_NOTIF_MODAL } from '../constants'

const initialState = {
  editUserOpened: false,
  userInfo: {},
  notifModalOpened: false,
  notifType: null,
  notifTitle: null,
  notifMessage: null,
}

export default (state = initialState, action) => {
  const { type, payload = {} } = action
  switch (type) {
    case OPEN_EDIT_USER:
      return {
        ...state,
        editUserOpened: true,
        userInfo: { ...payload.userInfo },
      }
    case CLOSE_EDIT_USER:
      return {
        ...state,
        editUserOpened: false,
        userInfo: {},
      }
    case OPEN_NOTIF_MODAL:
      return {
        ...state,
        notifModalOpened: true,
        notifType: payload.type,
        notifTitle: payload.title,
        notifMessage: payload.message,
      }
    case CLOSE_NOTIF_MODAL:
      return {
        ...state,
        notifModalOpened: false,
        notifTitle: null,
        notifMessage: null,
      }

    default:
      return state
  }
}
