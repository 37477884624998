import { RENTAL_LOADING, GET_RENTALS_SUCCEEDED, LOGOUT_SUCCEEDED } from '../constants'

const initialState = {
  allRentals: [],
  pagination: {
    page: 1,
    size: 10,
    nbOfPages: 1,
  },
  isLoading: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RENTAL_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case GET_RENTALS_SUCCEEDED:
      return {
        ...state,
        pagination: { ...state.pagination, ...payload.pagination },
        allRentals: payload.page ? payload.page.rentals : [],
      }
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
