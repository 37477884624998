import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import EditUserModal from '../edit-user-modal'
import { openEditUserModal } from '../../actions'

import './styles.scss'

const NavMenu = ({ openAddUserModal, isAdmin }) => {
  return (
    isAdmin && (
      <>
        <div className="nav-menu">
          <NavLink activeClassName="active" className="nav-menu-button" exact to="/">
            <img className="inactive" src="/assets/images/chart.png" alt="board" />
            <img className="active" src="/assets/images/chart_active.png" alt="board" />
            <label>Tableau de bord</label>
          </NavLink>
          <NavLink activeClassName="active" className="nav-menu-button" exact to="/racks">
            <img className="inactive" src="/assets/images/racks.png" alt="board" />
            <img className="active" src="/assets/images/racks_active.png" alt="board" />
            <label>Mes racks</label>
          </NavLink>
          <NavLink activeClassName="active" className="nav-menu-button" exact to="/users">
            <img className="inactive" src="/assets/images/users.png" alt="users" />
            <img className="active" src="/assets/images/users_active.png" alt="users" />
            <label>Mes collaborateurs</label>
          </NavLink>
          <button className="nav-menu-button" onClick={openAddUserModal} type="button">
            <img src="/assets/images/user_add.png" alt="add user" />
            <label>Ajouter un collaborateur</label>
          </button>
        </div>
        <EditUserModal />
      </>
    )
  )
}

const mapStateToProps = ({ user: { isAdmin } }) => ({
  isAdmin,
})

const mapDispatchToProps = dispatch => ({
  openAddUserModal: () => dispatch(openEditUserModal({})),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu)
