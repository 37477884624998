import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

import authSagas from './auth'
import racksSagas from './racks'
import rentalsSagas from './rentals'
import statsSagas from './stats'
import usersSagas from './users'

const sagaMiddleware = createSagaMiddleware()

function* rootSaga() {
  yield all([authSagas(), racksSagas(), rentalsSagas(), statsSagas(), usersSagas()])
}

export const initSagas = middleware => middleware.run(rootSaga)

export default sagaMiddleware
