import * as React from 'react'
import Modal from '../modal'

export default ({ isOpened, onClose }) => (
  <Modal isOpened={isOpened}>
    <div className="modal-block">
      <img src="/assets/images/valid.png" alt="Rack déverrouillé" />
      <button className="close-icon" onClick={onClose} type="button">
        <img src="/assets/images/clear.png" alt="close" />
      </button>
      <div className="modal-title">Rack déverrouillé</div>
      <div className="modal-title">avec succès</div>
      <div className="modal-text">Vous pouvez prendre la trottinette.!</div>
    </div>
  </Modal>
)
