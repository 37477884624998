import * as React from 'react'

import ActionButton from '../action-button'
import Modal from '../modal'

import './styles.scss'

export default ({ isOpened, closeModal, title, message, onConfirm }) => (
  <Modal onClose={closeModal} isOpened={isOpened} contentClassName="confirm-modal">
    <div className="modal-block">
      <button className="close-icon" onClick={closeModal} type="button">
        <img src="/assets/images/clear.png" alt="close" />
      </button>
      <div className="content">
        <div className="modal-title">{title}</div>
        <div className="modal-text">{message}</div>
        <ActionButton className={`button-black`} label="Confirmer" onClick={() => onConfirm()} />
        <ActionButton className={`black-border`} label="Annuler" onClick={closeModal} />
      </div>
    </div>
  </Modal>
)
