import {
  GET_RACKS,
  UNLOCK_RACK,
  FORCE_LOCK_RACK,
  FORCE_UNLOCK_RACK,
  SWITCH_TO_MODE,
} from '../constants'

export const getRacks = () => ({
  type: GET_RACKS,
})

export const unlockRack = rackId => ({
  type: UNLOCK_RACK,
  rackId,
})

export const forceLockRack = rackId => ({
  type: FORCE_LOCK_RACK,
  rackId,
})

export const forceUnlockRack = rackId => ({
  type: FORCE_UNLOCK_RACK,
  rackId,
})

export const switchToMode = (rackId, mode) => ({
  type: SWITCH_TO_MODE,
  rackId,
  mode,
})
