import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

import store, { persistor } from './store'
import Router from './router'
import { NotifModal } from './components'
import './Style/App.scss'

const honeybadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENV,
}

const honeybadger = Honeybadger.configure(honeybadgerConfig)

const root = document.getElementById('root')

const App = () => (
  <ErrorBoundary honeybadger={honeybadger}>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <div className="App">
          <header className="App-header">
            <Router />
            <NotifModal />
          </header>
        </div>
      </Provider>
    </PersistGate>
  </ErrorBoundary>
)

ReactDOM.render(<App />, root)
