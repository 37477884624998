import axios from 'axios'
import { get } from 'lodash'
import { Cookies } from 'react-cookie'
import { camelizeKeys, decamelizeKeys } from 'humps'

class Api {
  constructor() {
    this.jwt = null
    this.organizationId = null
    this.user = null
    this.cookie = new Cookies()

    this.initClient()
  }

  checkCookie() {
    this.initClient()

    return !!this.user
  }

  setCookie(data) {
    this.cookie.set('user', data, { path: '/' })
    this.initClient()
  }

  clearCookie() {
    this.cookie.remove('user', { path: '/' })
    this.jwt = null
    this.organizationId = null
    this.user = null
  }

  initClient() {
    const user = this.cookie.get('user')

    if (user) {
      this.user = user
      this.organizationId = get(this.user, 'session.membership.organization.id')
      this.jwt = get(this.user, 'session.token')
    }
  }

  get(target, onSuccess, onFail, query) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1${target}`,
        this.generateTokenHeader(null, query),
      )
      .then(res => onSuccess(res))
      .catch(err => onFail(err))
  }

  get2(target, query) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1${target}`,
        this.generateTokenHeader(null, query),
      )
      .then(response => camelizeKeys(response.data))
  }

  post(target, data, onSuccess, onFail) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1${target}`, data, this.generateTokenHeader())
      .then(res => onSuccess(res))
      .catch(err => onFail(err))
  }

  post2(target, params) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1${target}`,
        decamelizeKeys(params),
        this.generateTokenHeader(),
      )
      .then(({ data }) => camelizeKeys(data))
  }

  put(target, data, onSuccess, onFail) {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1${target}`, data, this.generateTokenHeader())
      .then(res => onSuccess(res))
      .catch(err => onFail(err))
  }

  put2(target, params) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1${target}`,
        decamelizeKeys(params),
        this.generateTokenHeader(),
      )
      .then(({ data }) => camelizeKeys(data))
  }

  delete(target, onSuccess, onFail, query) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v1${target}`,
        this.generateTokenHeader(null, query),
      )
      .then(res => onSuccess(res))
      .catch(err => onFail(err))
  }

  generateTokenHeader(contentType, query) {
    if (this.jwt || this.organizationId) {
      return {
        headers: {
          Authorization: `Bearer ${this.jwt}`,
          'Content-Type': contentType || 'application/json',
        },
        params: {
          organization_id: this.organizationId,
          ...query,
        },
      }
    }

    return { params: query }
  }
}

export default new Api(`${window.location.protocol}//${window.location.host}`)
