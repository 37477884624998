import * as React from 'react'
import { chunk } from 'lodash'

import './styles.scss'
import Pagination from '../pagination'
import { USER_PER_PAGE } from '../../constants'

export default class UserTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      totalPages: props.data ? Math.ceil(props.data.length / USER_PER_PAGE) : 0,
      currentPage: 1,
    }
  }

  paginationManagement(data, page) {
    return chunk(data, USER_PER_PAGE)[page - 1]
  }

  renderUsersData(data) {
    const { deleteUser, editUser, endRentals, isAdmin, isMasterAdmin } = this.props

    return (
      data &&
      data.map((line, index) => {
        const { firstName, lastName, email, id } = line

        return (
          <tr key={`user-${index}`}>
            <td>{lastName}</td>
            <td>{firstName}</td>
            <td>{email}</td>
            <td className="table-button-2">
              <button
                type="button"
                onClick={() =>
                  editUser({
                    firstName,
                    lastName,
                    email,
                    id,
                  })
                }
              >
                <img src="/assets/images/edit.svg" alt="delete" />
              </button>
              {isAdmin && (
                <>
                  {isMasterAdmin && (
                    <button type="button" onClick={() => endRentals({ userId: id })}>
                      <img src="/assets/images/force_end.png" alt="Terminer toutes les locations" />
                    </button>
                  )}
                  <button type="button" onClick={() => deleteUser(line)}>
                    <img src="/assets/images/delete_outlined.png" alt="delete" />
                  </button>
                  <a href={`/?user=${id}`}>
                    <img src="/assets/images/arrow-right.png" alt="edit" />
                  </a>
                </>
              )}
            </td>
          </tr>
        )
      })
    )
  }

  render() {
    const { data } = this.props
    const { totalPages, currentPage } = this.state

    return (
      <div className="table-bloc">
        <table>
          <tbody>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Mail</th>
              <th></th>
            </tr>
            {this.renderUsersData(this.paginationManagement(data, currentPage))}
          </tbody>
        </table>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onSelectPage={id => this.setState({ currentPage: id })}
        />
      </div>
    )
  }
}
