import * as React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { NavHeader, NavMenu, UserTable, ConditionalRedirect } from '../../components'
import { openEditUserModal, getUsers as getActionUsers, openNotifModal } from '../../actions'
import { deleteUser } from '../../api/users'
import ConfirmationModal from '../../components/confirmation-modal'
import { endRentals } from '../../actions'

class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmationOpened: false,
      selectedUser: null,
    }
  }

  componentDidMount() {
    const { getUsers } = this.props
    getUsers()
  }

  onDeleteUserClicked(user) {
    this.setState({ confirmationOpened: true, selectedUser: user })
  }

  onDeleteUserConfirmed() {
    const { getUsers, notif } = this.props
    const { selectedUser } = this.state
    const id = get(selectedUser, 'id', -1)
    this.setState({ confirmationOpened: false })
    if (id === -1) {
      notif('error', 'Erreur', "Erreur API, cet utilisateur n'a pas d'identifiant valide")
    } else {
      deleteUser(
        id,
        () => {
          getUsers()
          notif('success', 'Suppression terminée', "La suppression s'est terminée avec succès !")
        },
        () => notif('error', 'Erreur', 'Une erreur est survenue pendant la suppression'),
      )
    }
  }

  render() {
    const { isAdmin, isMasterAdmin, isLogged, openModal, users, endRentals } = this.props
    const { selectedUser, confirmationOpened } = this.state

    return (
      <div className="board-container">
        <NavHeader pageTitle="Mes collaborateurs" />
        <NavMenu />
        <div className="table-container with-menu">
          <UserTable
            data={users}
            deleteUser={user => this.onDeleteUserClicked(user)}
            editUser={openModal}
            isAdmin={isAdmin}
            isMasterAdmin={isMasterAdmin}
            endRentals={endRentals}
          />
        </div>
        <ConditionalRedirect condition={!isLogged || !isAdmin} to="/" />
        <ConfirmationModal
          title="Confirmation"
          message={`Etes vous sur de vouloir supprimer l'utilisateur ${selectedUser &&
            selectedUser.email} ?`}
          isOpened={confirmationOpened}
          onConfirm={() => this.onDeleteUserConfirmed()}
          closeModal={() => this.setState({ confirmationOpened: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ user: { isLogged, isAdmin, isMasterAdmin }, users }) => ({
  isLogged,
  isAdmin,
  isMasterAdmin,
  users: users.data,
})

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getActionUsers()),
  openModal: userInfo => dispatch(openEditUserModal(userInfo)),
  endRentals: userId => dispatch(endRentals(userId)),
  notif: (type, title, message) => dispatch(openNotifModal(type, title, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
