import api from './api'

export const login = user => api.post2('/auth/identity/callback', { user })

export const logout = action => {
  api.clearCookie()
  action()
}

export const getCurrentUser = () => api.get2('/me')
