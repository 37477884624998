import { GET_RENTALS, RENTAL_LOADING, END_RENTALS } from '../constants'

export const rentalLoading = isLoading => ({
  type: RENTAL_LOADING,
  payload: isLoading,
})

export const getRentals = (userId, size, page) => ({
  type: GET_RENTALS,
  params: { userId, size, page },
})

// should be either data.user_id or data.rental_id (if both rental_id will prevail)
export const endRentals = params => ({
  type: END_RENTALS,
  params,
})
