import { GET_RACKS_SUCCEEDED, LOGOUT_SUCCEEDED } from '../constants'

const initialState = {
  allRacks: [],
  isLoading: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_RACKS_SUCCEEDED:
      return {
        ...state,
        allRacks: payload,
      }
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
