import {
  LOGIN_USER_SUCCEEDED,
  LOGIN_USER_FAILED,
  LOGOUT_SUCCEEDED,
  REFRESH_SESSION_ERROR,
} from '../constants'

const initialState = {
  isLogged: false,
  isManager: false,
  isAdmin: false,
  isMasterAdmin: false,
  id: 0,
  email: '',
  role: '',
}

export default (state = initialState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case LOGIN_USER_FAILED:
      return {
        ...state,
        error: true,
      }
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }
    case LOGIN_USER_SUCCEEDED:
      return {
        ...state,
        ...payload,
        error: false,
        isLogged: true,
      }
    case REFRESH_SESSION_ERROR:
      return {
        ...state,
        isLogged: false,
      }
    default:
      return state
  }
}
