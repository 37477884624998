export const ADD_USER = 'ADD_USER'
export const CLOSE_EDIT_USER = 'CLOSE_EDIT_USER'
export const CLOSE_NOTIF_MODAL = 'CLOSE_ERROR_MODAL'
export const EDIT_USER = 'EDIT_USER'
export const GET_RACKS = 'GET_RACKS'
export const GET_RACKS_SUCCEEDED = 'GET_RACKS_SUCCEEDED'
export const GET_RENTALS = 'GET_RENTALS'
export const END_RENTALS = 'END_RENTALS'
export const GET_RENTALS_SUCCEEDED = 'GET_RENTALS_SUCCEEDED'
export const GET_STATS = 'GET_STATS'
export const GET_STATS_SUCCEEDED = 'GET_STATS_SUCCEEDED'
export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED'
export const LOGIN_USER_SUCCEEDED = 'LOGIN_USER_SUCCEEDED'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'
export const OPEN_EDIT_USER = 'OPEN_EDIT_USER'
export const OPEN_NOTIF_MODAL = 'OPEN_ERROR_MODAL'
export const REFRESH_SESSION = 'REFRESH_SESSION'
export const REFRESH_SESSION_ERROR = 'REFRESH_SESSION_ERROR'
export const REGISTER_USER = 'REGISTER_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const RENTAL_LOADING = 'RENTAL_LOADING'
export const UNLOCK_RACK = 'UNLOCK_RACK'
export const FORCE_LOCK_RACK = 'FORCE_LOCK_RACK'
export const FORCE_UNLOCK_RACK = 'FORCE_UNLOCK_RACK'
export const SWITCH_TO_MODE = 'SWITCH_TO_MODE'
