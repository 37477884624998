import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { map, chunk, filter, orderBy } from 'lodash'
import queryString from 'query-string'
import { get } from 'lodash'
import moment from 'moment'
import {
  ConditionalRedirect,
  NavHeader,
  NavMenu,
  ValueHighlight,
  Pagination,
} from '../../components'
import { getRentals as getApiRentals, getStats as getApiStats } from '../../actions'
import { RENTAL_PER_PAGE } from '../../constants'
import { endRentals } from '../../actions'

const Highlights = ({
  stats: {
    activeUsers,
    rentalsOnGoing,
    totalRentals,
    usersCount,
    liveRacks,
    racks,
    totalRentalsToday,
  },
}) => (
  <div className="highlight-container">
    <ValueHighlight label="racks en service" value={liveRacks} secondary={racks} />
    <ValueHighlight
      label="comptes actifs 24h vs total"
      value={activeUsers}
      secondary={usersCount}
    />
    <ValueHighlight
      label="locations 24h vs total"
      value={totalRentalsToday}
      secondary={totalRentals}
    />
    <ValueHighlight label="locations en cours" value={rentalsOnGoing} />
  </div>
)

class Rentals extends React.Component {
  constructor(props) {
    super(props)
  }

  formatDate(dateString) {
    return dateString && moment(dateString).format('YYYY/MM/DD, HH[h]mm')
  }

  render() {
    const { getRentals, rentals, pagination, userFilter, isMasterAdmin, endRentals } = this.props
    const nbOfPages = get(pagination, 'nbOfPages', 1)
    const page = get(pagination, 'page', 1)
    const size = get(pagination, 'size', 10)

    return (
      <div className="table-bloc">
        <table>
          <thead>
            <tr>
              <th>Trottinette</th>
              <th>Rack début</th>
              <th>Date de début</th>
              <th>Rack fin</th>
              <th>Date de fin</th>
              <th>Utilisateur</th>
              {isMasterAdmin && <th></th>}
            </tr>
          </thead>
          <tbody>
            {map(
              rentals,
              ({
                id,
                scooterBytesRef,
                startingRackBytesRef,
                arrivalRackBytesRef,
                startedAt,
                endedAt,
                renterIsAGuest,
                user,
              }) => {
                const endedClass =
                  moment(endedAt).diff(moment(startedAt)) > 86400000 ? 'color-red' : ''

                return (
                  <tr key={id}>
                    <td>{scooterBytesRef}</td>
                    <td>{startingRackBytesRef}</td>
                    <td>{this.formatDate(startedAt)}</td>
                    <td>{arrivalRackBytesRef}</td>

                    <td>
                      <span className={endedClass}>{this.formatDate(endedAt)}</span>
                    </td>
                    <td>{renterIsAGuest ? 'Invité•e' : get(user, 'email')}</td>
                    {isMasterAdmin && (
                      <td className="table-button-2">
                        {!endedAt && (
                          <button type="button" onClick={() => endRentals({ rentalId: id })}>
                            <img
                              src="/assets/images/force_end.png"
                              alt="Terminer toutes les locations"
                            />
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                )
              },
            )}
          </tbody>
        </table>
        <Pagination
          totalPages={nbOfPages}
          currentPage={page}
          onSelectPage={id => getRentals(userFilter, size, id)}
        />
      </div>
    )
  }
}

const Dashboard = ({
  getRentals,
  getStats,
  isManager,
  rentals,
  pagination,
  stats,
  location,
  isMasterAdmin,
  endRentals,
}) => {
  const userFilter = get(queryString.parse(location.search), 'user', null)
  useEffect(() => {
    getRentals(userFilter, RENTAL_PER_PAGE, 1)
    getStats()
  }, [])

  return (
    <div className="board-container">
      <ConditionalRedirect condition={isManager} to="/racks" />
      <NavHeader pageTitle="Tableau de bord" />
      <NavMenu />
      <div className="table-container with-menu">
        <Highlights stats={stats} />
        <Rentals
          rentals={rentals}
          pagination={pagination}
          getRentals={getRentals}
          userFilter={userFilter}
          isMasterAdmin={isMasterAdmin}
          endRentals={endRentals}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({
  rentals: { pagination, allRentals },
  stats,
  user: { isManager, isMasterAdmin },
}) => ({
  pagination,
  isManager,
  isMasterAdmin,
  stats,
  rentals: allRentals,
})

const mapDispatchToProps = dispatch => ({
  getRentals: (userId, size, page) => dispatch(getApiRentals(userId, size, page)),
  getStats: () => dispatch(getApiStats()),
  endRentals: userId => dispatch(endRentals(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
