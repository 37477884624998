import { get } from 'lodash'
import { call, takeLeading, put } from 'redux-saga/effects'
import api from '../api/api'
import { getCurrentUser, login as apiLogin } from '../api'

import {
  REFRESH_SESSION,
  REFRESH_SESSION_ERROR,
  LOGOUT_USER,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCEEDED,
} from '../constants'
import { ADMIN, MANAGER } from '../constants/roles'

const formattedSession = payload => {
  const role = get(payload, 'membership.role')
  //const role = get(payload, 'user.membership.role')
  if (role) {
    return {
      ...payload.user,
      lastRental: get(payload, 'membership.lastRental'),
      membership: {
        role,
        organizationId: get(payload, 'membership.organization.id'),
        organizationName: get(payload, 'membership.organization.name'),
      },
      isAdmin: role === ADMIN,
      isManager: role === MANAGER,
      isMasterAdmin: payload.user.isMasterAdmin === true,
    }
  }

  return false
}

function* logout() {
  try {
    console.log('Logging out')
    yield put({ type: LOGOUT_SUCCEEDED })
    api.clearCookie()
    window.location = '/login'
  } catch (e) {
    yield put({ type: LOGOUT_FAILED })
  }
}

function* refreshSession() {
  try {
    const payload = yield call(getCurrentUser)

    const formattedData = formattedSession(payload.me)

    if (formattedData) {
      yield put({ type: LOGIN_USER_SUCCEEDED, payload: formattedData })
    } else {
      yield put({ type: REFRESH_SESSION_ERROR })
    }
  } catch (error) {
    yield put({ type: REFRESH_SESSION_ERROR, error })
    api.clearCookie()

    window.location = '/login'
  }
}

function* login({ data }) {
  try {
    api.clearCookie()
    const payload = yield call(() => apiLogin(data))
    api.setCookie(payload)

    const formattedData = formattedSession(payload.session)

    if (formattedData) {
      yield put({
        type: LOGIN_USER_SUCCEEDED,
        payload: formattedData,
      })
    } else {
      yield put({ type: LOGIN_USER_FAILED })
    }
  } catch (err) {
    yield put({ type: LOGIN_USER_FAILED })
    api.clearCookie()
  }
}

function* authSaga() {
  yield takeLeading(REFRESH_SESSION, refreshSession)
  yield takeLeading(LOGOUT_USER, logout)
  yield takeLeading(LOGIN_USER, login)
}

export default authSaga
