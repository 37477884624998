import * as React from 'react'
import { connect } from 'react-redux'
import { get, has } from 'lodash'

import ActionButton from '../action-button'
import Input from '../input'
import Modal from '../modal'

import { closeEditUserModal, openNotifModal, getUsers } from '../../actions'
import { updateUser, createUser } from '../../api'

class EditUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEdit: has(props, 'userInfo.id'),
      firstName: get(props, 'userInfo.firstName', ''),
      lastName: get(props, 'userInfo.lastName', ''),
      email: get(props, 'userInfo.email', ''),
      error: false,
    }
    this.onChangeValue = this.onChangeValue.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { isOpened, userInfo } = this.props

    if (!prevProps.isOpened && isOpened) {
      if (!has(prevProps, 'userInfo.id') && has(userInfo, 'id')) {
        this.setState({
          isEdit: true,
          firstName: get(userInfo, 'firstName', ''),
          lastName: get(userInfo, 'lastName', ''),
          email: get(userInfo, 'email', ''),
        })
      } else {
        this.setState({
          isEdit: false,
          firstName: '',
          lastName: '',
          email: '',
        })
      }
    }
  }

  init() {
    this.setState({
      isEdit: false,
      firstName: '',
      lastName: '',
      email: '',
    })
  }

  testEmail(email) {
    const regexp = /^[a-zA-Z0-9.+]+@[a-zA-Z0-9-]+.[A-Za-z]+$/

    return regexp.test(email)
  }

  onChangeValue(key) {
    return e => {
      this.setState({ [key]: e.target.value })
    }
  }

  onSubmit() {
    const { notif, closeModal, getUserData, user } = this.props
    const { firstName, lastName, email, isEdit } = this.state

    if (this.isValid()) {
      if (isEdit) {
        updateUser(
          get(this.props, 'userInfo.id'),
          { user: { first_name: firstName, last_name: lastName } },
          () => {
            getUserData()
            closeModal()
            notif('success', 'Données mises à jour', "L'utilisateur a été mis à jour avec succès !")
            this.init()
          },
          err => {
            notif('error', 'Erreur', "Une erreur est survenue lors de l'enregistrement")
            console.error(err)
          },
        )
      } else {
        createUser(
          {
            membership: {
              user: { email, first_name: firstName, last_name: lastName },
              organization_id: get(user, 'membership.organizationId', null),
              role: 'guest',
              redirect_to: 'invitation',
            },
          },
          () => {
            getUserData()
            closeModal()
            notif('success', 'Invitation envoyée', 'Utilisateur invité avec succès !')
            this.init()
          },
          err => {
            notif('error', 'Erreur', "Une erreur est survenue lors de l'enregistrement")
            console.error(err)
          },
        )
      }
    } else {
      this.setState({ error: true })
      notif('error', 'Formulaire incomplet', 'Vous devez renseigner tout les champs')
    }
  }

  isValid() {
    const { firstName, lastName, email } = this.state

    return firstName !== '' && lastName !== '' && !!email && this.testEmail(email)
  }

  render() {
    const { isOpened, closeModal } = this.props
    const { isEdit, firstName, lastName, email, error } = this.state

    return (
      <Modal
        onClose={() => {
          closeModal()
          this.init()
        }}
        isOpened={isOpened}
        contentClassName="edit-user"
      >
        <div className="modal-block">
          <button
            className="close-icon"
            onClick={() => {
              closeModal()
              this.init()
            }}
            type="button"
          >
            <img src="/assets/images/clear.png" alt="close" />
          </button>
          <div className="content">
            <div className="modal-title">{isEdit ? 'Modifier' : 'Ajouter'} un collaborateur</div>
            <Input
              className={error && !firstName ? 'outline-red' : ''}
              label="Prénom"
              value={firstName}
              onChange={this.onChangeValue('firstName')}
            />
            <Input
              className={error && !lastName ? 'outline-red' : ''}
              label="Nom"
              value={lastName}
              onChange={this.onChangeValue('lastName')}
            />
            <Input
              className={`${error && (!email || !this.testEmail(email)) ? 'outline-red' : ''} ${
                isEdit ? 'disabled' : ''
              }`}
              label="Adresse mail"
              value={email}
              onChange={this.onChangeValue('email')}
              disabled={isEdit}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.onSubmit()
                }
              }}
            />
            <ActionButton
              className={`button-black ${this.isValid() ? '' : 'button-disabled'}`}
              label={isEdit ? 'Enregistrer' : 'Ajouter'}
              onClick={() => this.onSubmit()}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

const mSTP = ({ modal, user }) => ({
  isOpened: modal.editUserOpened,
  userInfo: modal.userInfo,
  user,
})

const mDTP = dispatch => ({
  getUserData: () => dispatch(getUsers()),
  closeModal: () => dispatch(closeEditUserModal()),
  notif: (type, title, message) => dispatch(openNotifModal(type, title, message)),
})

export default connect(mSTP, mDTP)(EditUser)
