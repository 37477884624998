import * as React from 'react'

export default ({ className, title, emoji, text }) => (
  <div className={`plume-title-block ${className}`}>
    <div className="plume-title">
      <span>{title}</span>
      {emoji && <img src={emoji} alt={title} />}
    </div>
    <div className="plume-text">{text}</div>
  </div>
)
