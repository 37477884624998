import * as React from 'react'
import { connect } from 'react-redux'

import {
  ActionButton,
  Input,
  LogoContainer,
  TitleBlock,
  ConditionalRedirect,
} from '../../components'

import '../../Style/Home.scss'
import { loginUser } from '../../actions'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: props.email,
      password: '',
    }

    this.onChangeValue = this.onChangeValue.bind(this)
  }

  onChangeValue(key) {
    return e => {
      this.setState({ [key]: e.target.value })
    }
  }

  formSubmit(e) {
    const { email, password } = this.state
    const { login } = this.props

    e.preventDefault()

    login(email, password)
  }

  render() {
    const { error, isLogged } = this.props
    const { email, password } = this.state

    const topInfo = {
      title: 'Bonjour',
      emoji: null,
      text: 'Renseignez les champs ci dessous pour vous connecter.',
    }

    return (
      <div className="account-page">
        <div className="account-splash">
          <LogoContainer />
        </div>
        <div className="account-container">
          <TitleBlock
            className="no-margin"
            title={topInfo.title}
            emoji={topInfo.emoji}
            text={topInfo.text}
          />
          <form className="account-form" onSubmit={e => this.formSubmit(e)}>
            <Input
              className={error ? 'error' : undefined}
              value={email}
              label="Identifiant"
              type="text"
              onChange={this.onChangeValue('email')}
            />
            <Input
              className={error ? 'error' : undefined}
              value={password}
              label="Mot de passe"
              type="password"
              onChange={this.onChangeValue('password')}
            />
            {email && password ? (
              <ActionButton
                className="button-black"
                type="submit"
                label="Se connecter"
                icon="/assets/images/arrow-right-blue.png"
              />
            ) : (
              <ActionButton disabled className="button-disabled" label="Se connecter" />
            )}
          </form>
          <ConditionalRedirect condition={isLogged} to="/" />
        </div>
      </div>
    )
  }
}

const MapStateToProps = ({ user }) => user

const MapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(loginUser({ email, password })),
})

export default connect(MapStateToProps, MapDispatchToProps)(Login)
