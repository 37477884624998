import { GET_USERS_SUCCEEDED } from '../constants'

const initialState = {
  data: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_USERS_SUCCEEDED:
      return {
        ...state,
        data: payload.users,
      }
    default:
      return state
  }
}
