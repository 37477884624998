import api from './api'

export const getUsers = () => api.get2('/users')

export const createUser = (params, onSuccess, onFail) =>
  api.post('/memberships', params, onSuccess, onFail)

export const updateUser = (id, params, onSuccess, onFail) =>
  api.put(`/users/${id}`, params, onSuccess, onFail)

export const deleteUser = (id, onSuccess, onFail) => api.delete(`/users/${id}`, onSuccess, onFail)
