import * as React from 'react'
import { Link } from 'react-router-dom'

import '../../Style/Button.scss'

export default ({ className, to, label, icon }) => (
  <div className={`button-block ${className}`}>
    <Link to={to}>{label}</Link>
    {icon && <img src={icon} alt={label} />}
  </div>
)
