import * as React from 'react'
import { connect } from 'react-redux'
import Modal from '../modal'
import { closeErrorModal } from '../../actions'

import './styles.scss'

class NotifModal extends React.Component {
  render() {
    const { isOpened, closeModal, type, title, message } = this.props

    return (
      <Modal isOpened={isOpened}>
        <div className="modal-block">
          {type === 'error' ? (
            <div className="error-icon">
              <img src="/assets/images/error.png" alt="error" />
            </div>
          ) : (
            <div className="success-icon">
              <img src="/assets/images/done.png" alt="success" />
            </div>
          )}
          <button className="close-icon" onClick={closeModal} type="button">
            <img src="/assets/images/clear.png" alt="close" />
          </button>
          <div className="modal-title">{title}</div>
          <div className="modal-text">{message}</div>
        </div>
      </Modal>
    )
  }
}

const mSTP = ({ modal }) => ({
  isOpened: !!modal.notifModalOpened,
  type: modal.notifType,
  title: modal.notifTitle,
  message: modal.notifMessage,
})

const mDTP = dispatch => ({
  closeModal: () => dispatch(closeErrorModal()),
})

export default connect(mSTP, mDTP)(NotifModal)
