import * as React from 'react'

export default ({ label, value, secondary }) => (
  <div className="highlight-bloc">
    <div className="highlight-value">
      {value}
      {!!secondary && <span>/{secondary}</span>}
    </div>
    <div className="highlight-label">{label}</div>
  </div>
)
