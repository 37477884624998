import { get, filter, sortBy, map, find, size } from 'lodash'
import { call, put, takeLeading, select } from 'redux-saga/effects'
import {
  createRental,
  getRacks as apiGetRacks,
  forceLock,
  forceUnlock,
  switchToModeApi,
} from '../api'
import {
  GET_RACKS,
  GET_RACKS_SUCCEEDED,
  GET_STATS_SUCCEEDED,
  UNLOCK_RACK,
  FORCE_LOCK_RACK,
  FORCE_UNLOCK_RACK,
  SWITCH_TO_MODE,
} from '../constants'

function* getRacks() {
  try {
    const data = yield call(apiGetRacks)
    const payload = sortBy(
      map(data.racks, rack => ({
        ...rack,
        live: find(data.liveRacks, { id: rack.uid }),
      })),
      'id',
    )
    const liveRacks = size(filter(payload, rack => !!rack.live))
    const emptyLiveRacks = size(filter(payload, rack => !!get(rack, 'live.detectedScooterId')))

    yield put({ type: GET_RACKS_SUCCEEDED, payload })
    yield put({
      type: GET_STATS_SUCCEEDED,
      payload: { liveRacks, emptyLiveRacks, racks: size(payload) },
    })
  } catch (e) {
    yield put({ type: GET_RACKS_SUCCEEDED, payload: {} })
  }
}

function* unlockRack({ rackId }) {
  try {
    const userId = yield select(store => store.user.id)
    yield call(() => createRental({ rackId, userId }))
  } catch (err) {
    console.warn(err)
  }
}

function* forceUnlockRack({ rackId }) {
  try {
    yield call(() => forceUnlock(rackId))
  } catch (err) {
    console.warn(err)
  }
}

function* forceLockRack({ rackId }) {
  try {
    yield call(() => forceLock(rackId))
  } catch (err) {
    console.warn(err)
  }
}

function* switchToMode({ rackId, mode }) {
  try {
    yield call(() => switchToModeApi(rackId, mode))
  } catch (err) {
    console.warn(err)
  }
}

function* racksSaga() {
  yield takeLeading(UNLOCK_RACK, unlockRack)
  yield takeLeading(FORCE_UNLOCK_RACK, forceUnlockRack)
  yield takeLeading(SWITCH_TO_MODE, switchToMode)
  yield takeLeading(FORCE_LOCK_RACK, forceLockRack)
  yield takeLeading(GET_RACKS, getRacks)
}

export default racksSaga
