import api from './api'

export const getRacks = () => {
  //api.get2('/stations')
  return api.get2('/racks')
}

export const updateRack = (id, rack, onSuccess, onFailed) =>
  api.put(`/racks/${id}`, rack, onSuccess, onFailed)

export const forceLock = (id, onSuccess, onFailed) =>
  api.put(`/racks/${id}/force-lock`, {}, onSuccess, onFailed)

export const forceUnlock = (id, onSuccess, onFailed) =>
  api.put(`/racks/${id}/force-unlock`, {}, onSuccess, onFailed)

export const switchToModeApi = (id, mode) => api.put2(`/racks/${id}/mode`, { mode })

export default { getRacks }
