import { get } from 'lodash'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import sagaMiddleware, { initSagas } from '../sagas'
import rootReducer from '../reducers'
import { refreshSession } from '../actions'

const persistConfig = {
  key: 'root-manager',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware))

initSagas(sagaMiddleware)

export const persistor = persistStore(store, undefined, () => {
  const user = get(store.getState(), 'user', {})

  if (user.isLogged) {
    store.dispatch(refreshSession())
  } else if (!window.location.pathname.match('^/login')) {
    window.location = '/login'
  }
})

export default store
