import { OPEN_EDIT_USER, CLOSE_EDIT_USER, OPEN_NOTIF_MODAL, CLOSE_NOTIF_MODAL } from '../constants'

export const openEditUserModal = userInfo => ({
  type: OPEN_EDIT_USER,
  payload: { userInfo },
})

export const closeEditUserModal = () => ({
  type: CLOSE_EDIT_USER,
})

export const openNotifModal = (type, title, message) => ({
  type: OPEN_NOTIF_MODAL,
  payload: { type, title, message },
})

export const closeErrorModal = () => ({
  type: CLOSE_NOTIF_MODAL,
})
