import React from 'react'

import '../../Style/Button.scss'

export default ({ className, disabled, onClick, label, icon, type }) => (
  <button
    disabled={disabled}
    className={`button-block ${className} ${disabled && 'button-disabled'}`}
    onClick={onClick}
    type={type}
  >
    <span>{label}</span>
    {icon && <img src={icon} alt={label} />}
  </button>
)
