import { combineReducers } from 'redux'

import user from './user'
import stats from './stats'
import racks from './racks'
import users from './users'
import modal from './modal'
import rentals from './rentals'

const reducers = combineReducers({
  modal,
  racks,
  rentals,
  stats,
  user,
  users,
})

export default reducers
