import React, { Component, Fragment } from 'react'

import './styles.scss'

class Pagination extends Component {
  renderBlocks() {
    const { totalPages, currentPage } = this.props

    return (
      <Fragment>
        {currentPage > 3 && this.renderBlock(currentPage - 2)}
        {currentPage > 2 && this.renderBlock(currentPage - 1)}
        {currentPage > 1 && this.renderBlock(currentPage)}
        {currentPage < totalPages - 1 && this.renderBlock(currentPage + 1)}
        {currentPage < totalPages - 2 && this.renderBlock(currentPage + 2)}
      </Fragment>
    )
  }

  renderArrow(left) {
    const { currentPage, onSelectPage } = this.props

    return (
      <div
        className="pagination-btn btn btn-outline-secondary mx-1"
        onClick={() => {
          onSelectPage(currentPage + (left ? -1 : 1))
        }}
      >
        <img src={`/assets/images/arrow-${left ? 'left' : 'right'}.svg`} alt="delete" />
      </div>
    )
  }

  renderPoints() {
    return <div className="pagination-btn btn p-0 mx-1">...</div>
  }

  renderBlock(value) {
    const { currentPage, onSelectPage, totalPages } = this.props

    return (
      value <= totalPages && (
        <div
          className={`pagination-btn btn mx-1 ${
            currentPage === value ? 'pagination-selected btn-secondary' : 'btn-outline-secondary'
          }`}
          onClick={() => onSelectPage(value)}
        >
          {value}
        </div>
      )
    )
  }

  render() {
    const { totalPages, currentPage } = this.props

    return (
      totalPages > 1 && (
        <div className="pagination-container">
          {currentPage > 1 && this.renderArrow(true)}
          {this.renderBlock(1)}
          {currentPage > 4 && this.renderPoints()}
          {this.renderBlocks()}
          {currentPage < totalPages - 3 && this.renderPoints()}
          {totalPages > 1 && currentPage < totalPages && this.renderBlock(totalPages)}
          {currentPage < totalPages && this.renderArrow(false)}
        </div>
      )
    )
  }
}

export default Pagination
