import * as React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import ActionButton from '../action-button'
import ConditionalRedirect from '../conditional-redirect'

import { logoutUser } from '../../actions'
import { logout as apiLogout } from '../../api'

const NavHeader = ({ pageTitle, logout, isLogged }) => (
  <div className="nav-header">
    <div className="nav-header-title">{pageTitle}</div>
    <div className="nav-header-logo">
      <img src="/assets/images/logo.png" className="nav-header-help" alt="logo" />
    </div>
    <ActionButton
      className="nav-header-signout icon-reverse small-margin"
      label="Déconnexion"
      icon="/assets/images/sign_out.png"
      onClick={() => apiLogout(logout)}
    />
    <ConditionalRedirect condition={!isLogged} to="/" />
  </div>
)

const mapStateToProps = ({ user: { isLogged } }) => ({ isLogged })

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader)
