import { call, put, takeEvery } from 'redux-saga/effects'
import { openNotifModal, getRentals as getRentalsAction } from '../actions'
import { getRentals as apiGetRentals, apiEndRentals } from '../api'
import { GET_RENTALS, GET_RENTALS_SUCCEEDED, END_RENTALS } from '../constants'

export function* getRentals(action) {
  try {
    const { userId, size, page } = action.params
    const payload = yield call(apiGetRentals, userId, size, page)
    yield put({ type: GET_RENTALS_SUCCEEDED, payload })
  } catch (e) {
    yield put({ type: GET_RENTALS_SUCCEEDED, payload: {} })
  }
}

export function* endRentals(action) {
  try {
    const { userId, rentalId } = action.params

    const params = {}
    if (rentalId) {
      params.rentalId = rentalId
    } else if (userId) {
      params.userId = userId
    }
    const payload = yield call(apiEndRentals, params)
    yield put(
      openNotifModal('success', 'Réservations terminées', 'Les réservations ont été terminées'),
    )
    yield put(getRentalsAction())
  } catch (e) {
    yield put(openNotifModal('error', 'Erreur', "Les réservations n'ont pas pu être terminées"))
  }
}

function* rentalsSaga() {
  yield takeEvery(GET_RENTALS, getRentals)
  yield takeEvery(END_RENTALS, endRentals)
}

export default rentalsSaga
