import { GET_STATS_SUCCEEDED, LOGOUT_SUCCEEDED } from '../constants'

const initialState = {}

export default (state = initialState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case GET_STATS_SUCCEEDED:
      return {
        ...state,
        ...payload,
      }
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
