import * as React from 'react'

import './styles.scss'

export default class Modal extends React.Component {
  render() {
    const { isOpened, contentClassName = '', children, onClose } = this.props

    return (
      isOpened && (
        <div className="modal-container">
          <div className="modal-background" onClick={onClose && onClose} />
          <div className={`modal-content ${contentClassName}`}>{children}</div>
        </div>
      )
    )
  }
}
