import { call, put, takeEvery } from 'redux-saga/effects'
import { getUsers as apiGetUsers } from '../api'
import { GET_USERS, GET_USERS_SUCCEEDED } from '../constants'

export function* getUsers() {
  try {
    const payload = yield call(apiGetUsers)
    yield put({ type: GET_USERS_SUCCEEDED, payload })
  } catch (e) {
    yield put({ type: GET_USERS_SUCCEEDED, payload: {} })
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, getUsers)
}

export default usersSaga
