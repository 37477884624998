import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { Login, Users, Racks, Dashboard } from '../scenes'

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/racks" component={Racks} />
      <Route path="/login" component={Login} />
      <Route path="/users" component={Users} />
    </Switch>
  </Router>
)
