import { call, put, takeLeading } from 'redux-saga/effects'
import { getStats as apiGetStats } from '../api'
import { GET_STATS_SUCCEEDED, GET_STATS } from '../constants'

function* getStats() {
  try {
    const payload = yield call(apiGetStats)
    yield put({ type: GET_STATS_SUCCEEDED, payload })
  } catch (e) {
    yield put({ type: GET_STATS_SUCCEEDED, payload: {} })
  }
}

function* racksSaga() {
  yield takeLeading(GET_STATS, getStats)
}

export default racksSaga
